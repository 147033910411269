<template>
	<div style="padding-bottom:20px;">
		<mobile-header active="/mobile/companyActive"></mobile-header>
		<h4 style="margin-bottom: 0;" class="font2">招投标培训</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div style="padding:0 20px;">
			<img src="@assets/images/companyActive/公司业务—培训.jpg" style="width: 100%;" />
		</div>
		<div style="padding:0 10px;color: #595959;font-size: 14px;line-height: 25px;">
			<p style="text-indent: 2em;">
				招投标培训服务是为企事业单位提供各种招标投标培训课程，旨在帮助客户规范招投标管理、降低招投标风险、提高招投标效率、提高成功概率。培训服务分为公益课、公开课、企业内训课。
			</p>
			<p style="text-indent: 2em;">
				招投研究院成立以来，已经为来自广东、北京、上海、天津、湖南、湖北、四川、山东、广西、云南等全国 500 多家企事业单位了招投标法律法规、政府采购法律法规、企业竞标能力提升、快速提升中标率、高分标书编制及审核等咨询培训服务，取得了良好的社会声誉。
			</p>
		</div>

		<div style="text-align: center;margin-top: 40px;" id="serveObject">
			<h4 style="margin-bottom: 0;" class="font2">服务对象</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="display: flex;text-align: center;">
				<div>
					<i class="iconfont icon-caigourenyuan"></i>
					<h5 style="color: #595959;">采购人</h5>
				</div>
				<div>
					<i class="iconfont icon-gongyingshang"></i>
					<h5 style="color: #595959;">供应商</h5>
				</div>
				<div>
					<i class="iconfont icon-jianguanbumen"></i>
					<h5 style="color: #595959;">监管部门</h5>
				</div>
				<div>
					<i class="iconfont icon-zhuanjia"></i>
					<h5 style="color: #595959;">专家</h5>
				</div>
			</div>
		</div>
		
		<div style="text-align: center;margin-top: 40px;">
			<h4 style="margin-bottom: 0;" class="font2">服务优势</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="display: flex;text-align: center;">
					<div style="flex: 1;padding:0 2px;" v-for="(item,index) in youshi.slice(0,3)" :key="index">
						<div style="border:#EBEEF5 1px solid;border-radius: 5px;">
							<div style="background-color:#1C88CF;color:#fff;padding:5px 0;">
								<p style="margin:5px;">
									<i class="iconfont" v-bind:class="item.icon" style="font-size:35px;"></i>
								</p>
								<p style="font-size: 14px;">{{item.title}}</p>
							</div>
							<p style="padding:5px;text-align: left;line-height:16px;font-size:12px;color: #595959;margin: 0;">
								{{item.des}}
							</p>
						</div>
					</div>
			</div>
		</div>
		
		<div style="margin-top: 40px;">
			<h4 style="margin-bottom: 0;" class="font2">经典课程</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="margin:10px 10px 40px 10px;" 
				v-for="(item,index) in course" 
				:key="index"
			>
				<div style="border-radius: 5px;background-color: #1C88CF;color: #fff;padding:15px 5px;">
					<img src="@assets/images/研究院logo白底.png" style="width:20px;vertical-align: middle;"/>
					<span>招投研究院</span>
					<span style="float: right;margin:0px 25px 0 0;">{{item.num}}</span>
					<h3 style="color:#D6B73A;white-space:nowrap;overflow: hidden;text-align: center;">{{item.title}}</h3>
					<p style="text-align: center;color:#fff;margin-bottom: 20px;font-size: 14px;font-weight: 550;font-family: 微软雅黑;">
						{{item.desc}}
					</p>
				</div>
				
				<h4 style="margin:5px 0 5px 0;">{{item.title}}</h4>
				<p style="color:#909399;font-size: 14px;margin: 0;">
					<span style="margin-right: 20px;">共180分钟</span>
					<span style="margin-right: 20px;">刘春胜</span>
					<span>300+人学过</span>
				</p>
			</div>
		</div>
		
		<div style="margin-top: 40px;">
			<h4 style="margin-bottom: 0;" class="font2">培训见证</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div id="demo" style="overflow:hidden;white-space: nowrap;background: #ccc;">
				<div id="demo1" style="display: inline-block;position:relative;width: 100%;">
					<div style="display: inline-block;padding:3px 2px 0px 2px;width: 150px;box-sizing: border-box;"
						v-for="(item,index) in zizhi" :key="index"><img :src="item.src" style="width:100%;" />
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import mobileHeader from "@components/mobileHeader";
	export default {
		name:'tenderTrain',
		metaInfo: {
			title: '招投标培训',
			meta: [{
					name: 'keyWords',
					content: '招投标培训,招投标能力,竞标,招投标风险,招投标效率'
				},
				{
					name: 'description',
					content: '招投标培训服务是为企事业单位提供各种招标投标培训课程，旨在帮助客户规范招投标管理、降低招投标风险、提高招投标效率、提高成功概率。培训服务分为公益课、公开课、企业内训课。'
				}
			]
		},
		components: {
			mobileHeader,
		},
		data() {
			return {
				course: [
					{
					id: 1,
					num:'第一期',
					title: "企业如何参与政府采购和招投标工作",
					desc:'名师解析 + 实战经验 + 在线答疑 + 进阶提升',
					},
					{
					id: 2,
					num:'第二期',
					title: "提升企业招投标能力培训(电力专场)",
					desc:'现场评估 + 案例解读 + 管理技巧 + 提升策略',
					},
					{
					id: 3,
					num:'第三期',
					title: "企业竞标能力提升培训班",
					desc:'意识篇/规避篇 + 技能篇 + 编制篇 + 提升篇',
					},
				],
				zizhi: [
					{src: require("@assets/images/tenderTrain/1.jpg")},
					{src: require("@assets/images/tenderTrain/2.jpg")},
					{src: require("@assets/images/tenderTrain/3.jpg")},
					{src: require("@assets/images/tenderTrain/4.jpg")},
					{src: require("@assets/images/tenderTrain/5.jpg")},
					{src: require("@assets/images/tenderTrain/6.jpg")},
				],
				youshi:[
					{
						icon:'icon-jingyan',
						title:'行业经验丰富',
						des:'具有11年招投标咨询、培训、招标代理、投标管理、现场评估等丰富经验的团队，亲临指导。'
					},
					{
						icon:'icon-bianzhi',
						title:'案例丰富',
						des:'课程全过程将解读100个以上的废标、投标文件编制案例，通过众多案例加深对招投标的理解。'
					},
					{
						icon:'icon-peixun',
						title:'多维度解读',
						des:'从招标管理、投标管理、招标代理、评标专家、现场评估专家等多维度解读如何快速提升中标率。'
					},
				],
			}
		},
		methods: {
		},
		mounted: function() {
			this.bus.$emit('headerImg', this.headerImgMobile);
		}
	}
</script>

<style scoped>
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
		text-align: center;
	}
	.borderBottom{
		text-align: center;
		margin:0 0 10px 0;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 20px;
	}
	#serveObject div div{
		flex: 1;
		padding: 10px;
	}
	#serveObject .iconfont{
		font-size:40px;
		color:#409EFF;
	}
	#demo1 {
		animation: myfirst 20s infinite;
		-webkit-animation: myfirst 20s infinite;
	}
	@keyframes myfirst {
		0% {
			left: 0px;
		}
		15% {
			left: -150px;
		}
	
		22% {
			left: -150px;
		}
	
		37% {
			left: -300px;
		}
	
		44% {
			left: -300px;
		}
	
		59% {
			left: -450px;
		}
	
		66% {
			left: -450px;
		}
	
		81% {
			left: -600px;
		}
	
		88% {
			left: -600px;
		}
		93% {
			left: -0px;
		}
	
		100% {
			left: -0px;
		}
	}
</style>


